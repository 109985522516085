import React from 'react';
import ContactForm from './ContactForm';
import config from '../../config';
import logo from "../assets/img/Logo.png"
import {Link} from "gatsby";

export default function Footer() {
    const today = new Date();
    const year = today.getFullYear();
    return (
        <footer id="footer">
            <div className="inner">
                <section>
                    <h2>Información</h2>
                    <img src={logo} alt="logo" style={{width: 180}}/>
                    <dl style={{paddingTop: 10}}>
                        <dd style={{margin: 0}}>admin@foxglove.pro</dd>
                        <dd style={{margin: 0}}>+52 (55) 5344 6775</dd>
                        <dd style={{margin: 0}}>Horario 9:00–18:00 HRS</dd>
                    </dl>
                </section>
                <section>
                    <h2>Redes sociales</h2>
                    <ul className="icons">
                        {config.socialLinks.map(social => {
                            const {icon, name, url} = social;
                            return (
                                <li key={url}>
                                    <a href={url} className={`icon ${icon}`}>
                                        <span className="label">{name}</span>
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </section>
                <section>
                    <h2>Ayuda</h2>
                    <dl style={{paddingTop: 10}}>
                        <dd style={{margin: 0}}><
                            Link to="/about">Más información</Link>
                        </dd>
                        <dd style={{margin: 0}}><
                            Link to="/contact">Contacto</Link>
                        </dd>
                    </dl>
                </section>
                <ul className="copyright">
                    <li>&copy; {year} FOXGLOBLE</li>
                </ul>
            </div>
        </footer>
    );
}
