import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import {StaticQuery, graphql} from 'gatsby';

import '../assets/sass/main.scss';
import Footer from './Footer';
import Sidebar from './Sidebar';
import WhatsApp from "./WhatsappButton";

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPreloaded: true,
        };
    }

    componentDidMount() {
        this.timeoutId = setTimeout(() => {
            this.setState({isPreloaded: false});
        }, 100);
    }

    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    render() {
        const {children} = this.props;
        const {isPreloaded} = this.state;
        return (
            <StaticQuery
                query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
                render={data => (
                    <>
                        <Helmet
                            title={data.site.siteMetadata.title}
                            meta={[
                                {name: 'description', content: 'Phantom'},
                                {name: 'keywords', content: 'site, web'},
                            ]}

                        >
                            <html lang="en"/>
                            <link rel="stylesheet"
                                  href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
                            >
                            </link>

                            <script src="https://unpkg.com/react-id-swiper@2.3.1/lib/react-id-swiper.js"></script>
                            <script src="https://unpkg.com/react-id-swiper@2.3.1/lib/react-id-swiper.min.js"></script>
                            <link
                                rel="stylesheet"
                                href="https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.5.0/css/swiper.css"
                            ></link>
                            <link
                                rel="stylesheet"
                                href="https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.5.0/css/swiper.min.css"
                            ></link>

                        </Helmet>
                        <div className={isPreloaded ? 'main-body is-preload' : 'main-body'}>
                            <div id="wrapper">
                                <Sidebar/>
                                {children}
                                <Footer/>
                            </div>
                        </div>
                    </>
                )}
            />
        );
    }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
